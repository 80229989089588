<template>
    <div>
        <div class="dialogTableSort-tableHeader">
            <div>床位号</div>
            <div>姓名</div>
            <div>班级</div>
            <div>唯一号</div>
            <div>操作</div>
        </div>
        <div class="dialogTableSort-tableBody">
            <div class="left">
                <div
                    v-for="(item, index) of studentArray"
                    :key="index"
                >
                    {{ index + 1 }}
                </div>
            </div>
            <div
                class="right rightDraggable"
                v-loading="loading"
            >
                <!-- <draggable  :list="studentArray" >
                    <div class="right-item" :data-tarIndex="index" v-for="(item, index) of studentArray" :key="index">
                        <div class="dialogTableSort-name">
                            <div v-if="item" class="dialogTableSort-name-img"></div>
                            <div v-if="item">{{ item.studentName }}</div>
                        </div>
                        <div>
                            <el-tooltip v-if="item && item.className && item.className.length >= 10" class="item"
                                effect="dark" :content="item.className" placement="top">
                                <span v-if="item">{{ item.className }}</span>
                            </el-tooltip>
                            <span v-if="item && item.className && item.className.length < 10">{{ item.className
                                }}</span>
                        </div>
                        <div>
                            <el-tooltip v-if="item && item.onlyCode && String(item.onlyCode).length >= 12" class="item"
                                effect="dark" :content="item.onlyCode" placement="top">
                                <span>{{ item.onlyCode }}</span>
                            </el-tooltip>
                            <span v-if="item && item.onlyCode && String(item.onlyCode).length < 12">{{ item.onlyCode
                                }}</span>
                        </div>
                        <div>
                            <span v-if="item" class="moveOut" @click="moveOutBtn(index)">移出</span>
                            <span v-else class="distribution" @click="distributionBtn(index + 1)">分配</span>
                        </div>
                    </div>
                </draggable> -->

                <div
                    class="right-item"
                    :draggable="isDraggable(item)"
                    :data-tarIndex="index"
                    :data-tarMove="item ? item.havePermissions : 1"
                    v-for="(item, index) of studentArray"
                    :key="index"
                >
                    <div
                        :data-tarIndex="index"
                        :data-tarMove="item ? item.havePermissions : 1"
                        class="dialogTableSort-name"
                    >
                        <div
                            :data-tarIndex="index"
                            :data-tarMove="item ? item.havePermissions : 1"
                            v-if="item"
                            class="dialogTableSort-name-img"
                        ></div>
                        <div
                            :data-tarIndex="index"
                            :data-tarMove="item ? item.havePermissions : 1"
                            v-if="item"
                        >
                            {{ item.studentName }}
                        </div>
                    </div>
                    <div
                        :data-tarIndex="index"
                        :data-tarMove="item ? item.havePermissions : 1"
                    >
                        <el-tooltip
                            :data-tarIndex="index"
                            :data-tarMove="item ? item.havePermissions : 1"
                            v-if="
                                item &&
                                item.className &&
                                item.className.length >= 10
                            "
                            class="item"
                            effect="dark"
                            :content="item.className"
                            placement="top"
                        >
                            <span
                                :data-tarIndex="index"
                                :data-tarMove="item ? item.havePermissions : 1"
                                v-if="item"
                                >{{ item.className }}</span
                            >
                        </el-tooltip>
                        <span
                            :data-tarIndex="index"
                            :data-tarMove="item ? item.havePermissions : 1"
                            v-if="
                                item &&
                                item.className &&
                                item.className.length < 10
                            "
                            >{{ item.className }}</span
                        >
                    </div>
                    <div
                        :data-tarIndex="index"
                        :data-tarMove="item ? item.havePermissions : 1"
                    >
                        <el-tooltip
                            :data-tarIndex="index"
                            :data-tarMove="item ? item.havePermissions : 1"
                            v-if="
                                item &&
                                item.onlyCode &&
                                String(item.onlyCode).length >= 12
                            "
                            class="item"
                            effect="dark"
                            :content="item.onlyCode"
                            placement="top"
                        >
                            <span
                                :data-tarIndex="index"
                                :data-tarMove="item ? item.havePermissions : 1"
                                >{{ item.onlyCode }}</span
                            >
                        </el-tooltip>
                        <span
                            :data-tarIndex="index"
                            :data-tarMove="item ? item.havePermissions : 1"
                            v-if="
                                item &&
                                item.onlyCode &&
                                String(item.onlyCode).length < 12
                            "
                            >{{ item.onlyCode }}</span
                        >
                    </div>
                    <div
                        :data-tarIndex="index"
                        :data-tarMove="item ? item.havePermissions : 1"
                    >
                        <span
                            :data-tarIndex="index"
                            :data-tarMove="item ? item.havePermissions : 1"
                            v-if="item && item.havePermissions == 1"
                            class="moveOut"
                            @click="moveOutBtn(index)"
                            >移出</span
                        >
                        <span
                            :data-tarIndex="index"
                            :data-tarMove="item ? item.havePermissions : 1"
                            v-if="!item"
                            class="distribution"
                            @click="distributionBtn(index + 1)"
                            >分配</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="dialogTableSort-footer">
            <el-button
                size="small"
                @click="cloneBtn"
                >取消</el-button
            >
            <el-button
                type="primary"
                @click="primaryBtn"
                size="small"
                >确定</el-button
            >
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
    name: "DialogTableSort",
    components: {
        draggable,
    },
    props: {
        studentObj: {
            // 学生对象
            type: Object,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            studentArray: [],
            dragIndex: "",
            clickBedNum: "",
            startIndex: 0,
            endIndex: 0,
            isChange: "1",
        };
    },
    watch: {
        // 监听数据
        "studentObj.studentsList": {
            immediate: true,
            deep: true,
            handler() {
                // 拿到当前宿舍的容量和当前宿舍的学生
                let {studentsList, maxNum} = this.studentObj;
                this.studentArray = [];
                // 分配床位用空床位填充
                if (maxNum > 0) {
                    this.studentArray = Array(maxNum).fill("");
                }
                // 学生列表
                if (studentsList.length > 0) {
                    let nullNumArr = []; //没有床号的学生
                    studentsList.forEach((item, index) => {
                        if (item.bedNum) {
                            // 给学生分配床位
                            this.studentArray[item.bedNum - 1] = item;
                        } else {
                            nullNumArr.push(item);
                        }
                    });

                    let nullIndex = []; //当前空白的床位位置信息
                    this.studentArray.forEach((item, index) => {
                        if (!item) {
                            nullIndex.push(index);
                        }
                    });

                    // 将没有床号的学生分配到空床位
                    nullNumArr.forEach((item, index) => {
                        let key = nullIndex.shift();
                        this.studentArray[key] = item;
                    });

                    // 数组重新赋值实现响应式
                    this.studentArray = JSON.parse(
                        JSON.stringify(this.studentArray),
                    );

                    console.debug(this.studentArray, "dsfsfsdf");
                }
            },
        },
    },
    mounted() {
        this.$eventBus.$on("dialogTableSort", this.getFPStudent);
        let node = document.querySelector(".rightDraggable");
        node.ondragstart = this.start;
        node.ondragenter = this.dragenter;
        node.ondragend = this.dragend;
    },
    methods: {
        start(e) {
            let index = e.target.dataset.tarindex;
            this.startIndex = index;
        },
        dragenter(e) {
            console.debug(e.target.dataset);
            let index = e.target.dataset.tarindex;
            this.endIndex = index;
            this.isChange = e.target.dataset.tarmove;
        },
        dragend(e) {
            if (this.isChange != "1") return;
            let startIndex = this.startIndex;
            let endIndex = this.endIndex;
            if (startIndex == endIndex) return;
            let first = this.studentArray[startIndex];
            let second = this.studentArray[endIndex];
            this.studentArray.splice(startIndex, 1, second);
            this.studentArray.splice(endIndex, 1, first);
        },
        isDraggable(item) {
            let bol = false;
            if (!item) {
                bol = true;
            } else {
                if (item.havePermissions == 1) {
                    bol = true;
                }
            }
            return bol;
        },
        // 分配按钮
        distributionBtn(index) {
            // 保存点击的床位号
            this.clickBedNum = index - 1;
            let {maxNum} = this.studentObj;
            let studentArray = JSON.parse(JSON.stringify(this.studentArray));
            // 当前宿舍的剩余床位数
            let nullCount = maxNum - studentArray.filter((item) => item).length;
            this.$emit("distribution", {
                nullCount,
            });
        },
        // 移出按钮
        moveOutBtn(index) {
            this.$set(this.studentArray, index, "");
        },
        // 二级弹窗点击确定后将分配的学生信息传到这里
        getFPStudent(data) {
            data.forEach((item) => {
                item.havePermissions = 1;
            });
            if (!Array.isArray(data)) return;
            // 拿到当前宿舍的唯一号
            let onlyCode = [];
            this.studentArray.forEach((item) => {
                if (item) {
                    onlyCode.push(item.onlyCode);
                }
            });

            // 判断是否是选择的一个学生的情况
            if (data.length == 1) {
                // 判断当前宿舍是否已经分配了该学生
                if (onlyCode.includes(data[0].onlyCode)) return;
                this.$set(this.studentArray, this.clickBedNum, data[0]);
                return;
            }
            // 选择了多个学生
            if (data.length > 1) {
                // 拿到第一个未分配的学生信息
                let studentData = this.selectStudentFirst(data, onlyCode);
                // 判断是否有未分配的学生
                if (!studentData) {
                    return;
                }
                // 将其分配到点击的床位号
                this.$set(this.studentArray, this.clickBedNum, studentData);

                // 将其他学生分配到剩余的床位号
                this.studentArray.forEach((item, index) => {
                    if (!item && data.length > 0) {
                        let studentData = this.selectStudentFirst(
                            data,
                            onlyCode,
                        );
                        if (!studentData) {
                            return;
                        }
                        this.$set(this.studentArray, index, studentData);
                    }
                });
            }
        },
        // 批量选择学生
        selectStudentFirst(data, onlyCode) {
            if (data.length > 0) {
                let firstData = data.shift();
                if (onlyCode.includes(firstData.onlyCode)) {
                    return this.selectStudentFirst(data, onlyCode);
                }
                return firstData;
            } else {
                return false;
            }
        },
        cloneBtn() {
            this.$emit("handleClose");
        },
        primaryBtn() {
            let studentArray = this.studentArray;
            let studentsList = [];
            studentArray.forEach((item, index) => {
                if (item) {
                    item.bedNum = index + 1;
                    studentsList.push(item);
                }
            });

            studentsList = studentsList.map((item) => {
                return {
                    onlyCode: item.onlyCode,
                    className: item.className,
                    bedNum: String(item.bedNum),
                    classId: item.classId,
                    studentName: item.studentName,
                    studentType: item.studentType,
                    schoolId: item.schoolId,
                    allocationStatus: item.allocationStatus,
                    // ...item
                };
            });

            this.$emit("primary", studentsList);
        },
    },
    beforeDestroy() {
        this.$eventBus.$off("dialogTableSort", this.getFPStudent);
    },
};
</script>

<style lang="scss" scoped>
div {
    box-sizing: border-box;
}

.dialogTableSort-tableHeader {
    display: flex;

    div {
        flex-shrink: 0;
        width: 160px;
        background-color: #f3f3f3;
        text-align: center;
        padding: 14px;
        user-select: none;
    }
}

.dialogTableSort-tableBody {
    display: flex;

    .left {
        width: 160px;
        height: 40px;

        div {
            width: 160px;
            text-align: center;
            // padding: 12px;
            height: 45px;
            line-height: 45px;
            border: 1px solid #edf0f2;
            user-select: none;
            overflow: hidden;
        }
    }

    .right {
        flex: 1;

        .right-item {
            display: flex;
            background-color: white;

            & > div {
                width: 160px;
                text-align: center;
                height: 45px;
                line-height: 45px;
                overflow: hidden;
                border: 1px solid #edf0f2;
                padding: 0 12px;
                white-space: nowrap;
                /* 确保文本不换行 */
                overflow: hidden;
                /* 超出容器部分隐藏 */
                text-overflow: ellipsis;
                /* 超出部分用省略号表示 */
                user-select: none;
            }

            .moveOut {
                font-weight: 400;
                font-size: 14px;
                color: #f56c6c;
                user-select: none;
                cursor: pointer;
            }

            .distribution {
                font-weight: 400;
                font-size: 14px;
                color: #3c7fff;
                user-select: none;
                cursor: pointer;
            }
        }

        .right-item:hover .dialogTableSort-name-img {
            background: url("../../../../assets/images/dragActive.png")
                no-repeat center;
        }

        .right-item:hover {
            & > div {
                border-top: 1px solid #3c7fff;
                border-bottom: 1px solid #3c7fff;
            }

            & > div:first-of-type {
                border-left: 1px solid #3c7fff;
            }

            & > div:last-of-type {
                border-right: 1px solid #3c7fff;
            }
        }

        .right-item:hover .dialogTableSort-name-img {
            background: url("../../../../assets/images/dragActive.png")
                no-repeat center;
        }

        .dragstart {
            background-color: transparent;
            color: transparent;
        }

        .dialogTableSort-name-img {
            width: 30px;
            background: url("../../../../assets/images/drag.png") no-repeat
                center;
        }

        .dialogTableSort-name {
            display: flex;

            & > div:nth-of-type(1) {
                margin-right: 5px;
            }

            & > div:nth-of-type(2) {
                flex: 1;
                text-align: left;
                white-space: nowrap;
                /* 确保文本不换行 */
                overflow: hidden;
                /* 超出容器部分隐藏 */
                text-overflow: ellipsis;
                /* 超出部分用省略号表示 */
            }
        }
    }
}

.dialogTableSort-footer {
    padding-top: 20px;
    text-align: right;
}
</style>