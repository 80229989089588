<template>
    <div>
        <div class="dialogTableSort-tableHeader">
            <div>床位号</div>
            <div>姓名</div>
            <div>班级</div>
            <div>唯一号</div>
        </div>
        <div class="dialogTableSort-tableBody">
            <div class="left">
                <div v-for="(item, index) of studentArray" :key="index">{{ index + 1 }}</div>
            </div>
            <div class="right" v-loading="loading">

                <div class="right-item" v-for="(item, index) of studentArray" :key="index">
                    <div>
                        <div v-if="item && item.studentName">{{ item.studentName }}</div>
                        <div v-else>-</div>
                    </div>
                    <div>
                        <el-tooltip v-if="item && item.className && item.className.length >= 10" class="item"
                            effect="dark" :content="item.className" placement="top">
                            <span>{{ item.className }}</span>
                        </el-tooltip>
                        <span v-if="item && item.className && item.className.length < 10">{{ item.className
                            }}</span>
                        <span v-if="!(item && item.className)">-</span>
                    </div>
                    <div>
                        <el-tooltip v-if="item && item.onlyCode && item.onlyCode.length >= 15" class="item"
                            effect="dark" :content="item.onlyCode" placement="top">
                            <span>{{ item.onlyCode }}</span>
                        </el-tooltip>
                        <span v-if="item && item.onlyCode && item.onlyCode.length < 15">{{ item.onlyCode
                            }}</span>
                        <span v-if="!(item && item.onlyCode)">-</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DialogTableShow",
    props: {
        studentObj: {            // 学生对象
            type: Object,
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            studentArray: [],
            dragIndex: '',
            clickBedNum: '',
        }
    },
    watch: {
        "studentObj.studentsList": {
            immediate: true,
            deep: true,
            handler() {
                let { studentsList, maxNum } = this.studentObj;
                this.studentArray = [];
                if (maxNum > 0) {
                    this.studentArray = Array(maxNum).fill("");
                }
                if (studentsList.length > 0) {
                    let nullNumArr = []; //没有床号的学生
                    studentsList.forEach((item, index) => {
                        if (item.bedNum) {
                            this.studentArray[item.bedNum - 1] = item;
                        } else {
                            nullNumArr.push(item);
                        }
                    })

                    let nullIndex = []  //当前空白的床位位置信息
                    this.studentArray.forEach((item, index) => {
                        if (!item) {
                            nullIndex.push(index);
                        }
                    })

                    nullNumArr.forEach((item, index) => {
                        let key = nullIndex.shift();
                        this.studentArray[key] = item;
                    })

                    this.studentArray = JSON.parse(JSON.stringify(this.studentArray));

                }
            }
        },
    },
    methods: {
        cloneBtn() {
            this.$emit("handleClose");
        }
    },
}
</script>

<style lang="scss" scoped>
div {
    box-sizing: border-box;
}

.dialogTableSort-tableHeader {
    // border: 1px solid black;
    // border: 1px solid #EDF0F2;
    display: flex;

    &>div {
        flex-shrink: 0;
        width: 200px;
        background-color: #F3F3F3;
        text-align: center;
        padding: 14px;
        user-select: none;
    }
}

.dialogTableSort-tableBody {

    display: flex;

    .left {
        width: 200px;
        height: 40px;
        flex-shrink: 0;

        div {
            text-align: center;
            // padding: 12px;
            height: 45px;
            line-height: 45px;
            border: 1px solid #EDF0F2;
            user-select: none;
            overflow: hidden;
        }
    }

    .right {
        flex: 1;
        flex-shrink: 0;
        .right-item {
            display: flex;
            background-color: white;

            &>div {
                width: 200px;
                text-align: center;
                height: 45px;
                line-height: 45px;
                overflow: hidden;
                border: 1px solid #EDF0F2;
                padding: 0 12px;
                white-space: nowrap;
                /* 确保文本不换行 */
                overflow: hidden;
                /* 超出容器部分隐藏 */
                text-overflow: ellipsis;
                /* 超出部分用省略号表示 */
                user-select: none;
            }

            .moveOut {
                font-weight: 400;
                font-size: 14px;
                color: #F56C6C;
                user-select: none;
                cursor: pointer;
            }

            .distribution {
                font-weight: 400;
                font-size: 14px;
                color: #3C7FFF;
                user-select: none;
                cursor: pointer;
            }

        }

        .dragstart {
            background-color: transparent;
            color: transparent;
        }

        .dialogTableSort-name {
            display: flex;

            &>div:nth-of-type(1) {
                margin-right: 5px;
            }

            &>div:nth-of-type(2) {
                flex: 1;
                text-align: left;
                white-space: nowrap;
                /* 确保文本不换行 */
                overflow: hidden;
                /* 超出容器部分隐藏 */
                text-overflow: ellipsis;
                /* 超出部分用省略号表示 */
            }
        }
    }
}

.dialogTableSort-footer {
    padding-top: 20px;
    text-align: right
}
</style>